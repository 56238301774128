import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
//import Link from '../../../../components/typography/link';
import BackBarSC from './back-bar.styled';
import { ROUTE_NEWS } from '../../../../constants';

export default () => {
  return (
    <BackBarSC>
      <div className="inner">
        <Link className="stripe-black" to={ROUTE_NEWS}>
          <FormattedMessage id="news-detail_back-label" />
        </Link>
      </div>
    </BackBarSC>
  );
};
