import styled from 'styled-components';
import { COLOR_DARK_GREY_1, COLOR_PRIMARY_BLACK_1 } from '../../../constants';
import { bpMediumMax } from '../../../assets/styles/breakpoints';

export default styled.div`
  height: 420px;
  background-color: ${COLOR_PRIMARY_BLACK_1};

  .footer-content {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;

    .contact-us {
      height: 295px;
      display: flex;
      justify-content: space-between;

      .contact-us-text {
        margin-top: 100px;
      }

      .appointment-button {
        margin-top: 125px;
      }
    }

    .bottom-navbar {
      height: 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px rgba(255, 255, 255, 0.13) solid;

      .text {
        align-self: center;
        margin: 0;
      }

      .nav-links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;
        height: 100%;

        .nav-link {
          color: rgba(255, 255, 255, 0.5);
          font-family: 'PT Sans', sans-serif;
          font-size: 14px;
          line-height: 20px;
          text-decoration: none;
          height: 100%;
          display: flex;
          align-items: center;

          &.active {
            color: #ffffff;
          }
        }
      }
    }
  }

  @media (max-width: ${bpMediumMax}px) {
    height: auto;

    .footer-content {
      .contact-us {
        flex-direction: column;
        height: auto;
        .contact-us-text {
          margin-top: 50px;
          text-align: center;
        }

        .appointment-button {
          margin: 20px auto 50px auto;
        }
      }

      .bottom-navbar {
        height: auto;
        flex-direction: column-reverse;
        .nav-links {
          flex-direction: column;
          margin-top: 50px;
          margin-bottom: 50px;

          .nav-link {
            margin-top: 15px;
            &:first-child {
              margin-top: 0;
            }
          }
        }

        p {
          font-size: 14px;
          line-height: 1.43;
          color: ${COLOR_DARK_GREY_1};
          margin-bottom: 50px !important;
        }
      }
    }

    h2 {
      font-size: 27px;
      line-height: 1.48;
    }
  }
`;
