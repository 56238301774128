import React from 'react';
import PropTypes from 'prop-types';
import ServiceSC from './service-section.styled';
import HeadingTwo from '../typography/heading-two';
import Button from '../typography/button';
import HeadingThree from '../typography/heading-three';
import { ROUTE_SERVICES_AND_LEGISLATIVE } from '../../constants';
import { NavLink } from 'react-router-dom';

const ServiceSection = ({ showFull }) => {
  return (
    <ServiceSC>
      <div className="top-side">
        <div className="services-text">
          <HeadingTwo>
            Ponúkame vám bohatú <br /> paletu služieb
          </HeadingTwo>
        </div>
        <div className="services-button">
          <NavLink
            exact
            className="nav-link"
            activeClassName="active"
            to={ROUTE_SERVICES_AND_LEGISLATIVE}
          >
            <Button type="secondary">Chcem vedieť viac</Button>
          </NavLink>
        </div>
      </div>
      <div className="bottom-side">
        <div className="left">
          <div className="column">
            <div className="description-container">
              <HeadingThree className="description">Notárske zápisnice a zmluvy</HeadingThree>
            </div>
            <ul className="list">
              <li>kúpne zmluvy</li>
              <li>darovacie zmluvy</li>
              <li>zmluvy o zriadení alebo zrušení Vecného bremena, podielového spoluvlastníctva</li>
            </ul>
          </div>
        </div>
        <div className="middle">
          <div className="column">
            <div className="description-container">
              <HeadingThree className="description">
                Osvedčovanie právne významných skutočností
              </HeadingThree>
            </div>
            <ul className="list">
              <li>osvedčenie o správnosti odpisu alebo fotokópie listín</li>
              <li>osvedčenie pravosti podpisu na listine</li>
              <li>osvedčenie o priebehu valných zhromaždení a zasadaní právnických osôb</li>
            </ul>
          </div>
        </div>
        <div className="right">
          <div className="column">
            <div className="description-container">
              <HeadingThree className="description">Právne rady</HeadingThree>
            </div>
            <ul className="list">
              <li>
                poskytovanie právneho poradenstva súvisiaceho s činnosťou notára fyzickým a
                právnickým osobám
              </li>
              <li>
                výkon správy majetku a zatupovanie fyzických a právnických osôb v súvislosti s
                výkonom správy majetku
              </li>
            </ul>
          </div>
        </div>
      </div>
      {showFull ? (
        <div className="bottom-side">
          <div className="left second-row">
            <div className="column">
              <div className="description-container">
                <HeadingThree className="description">Súdny komisár</HeadingThree>
              </div>
              <ul className="list">
                <li>prejednávanie dedičských vecí na základe poverenia súdu</li>
              </ul>
            </div>
          </div>
          <div className="middle second-row">
            <div className="column">
              <div className="description-container">
                <HeadingThree className="description">Notárske úschovy</HeadingThree>
              </div>
              <ul className="list">
                <li>úschova listín, najmä závetov</li>
                <li>
                  úschova peňazí a cenných papierov za účelom ich vydania po splnení podmienok
                  konkrétnemu príjemcovi
                </li>
              </ul>
            </div>
          </div>
          <div className="right second-row">
            <div className="column">
              <div className="description-container">
                <HeadingThree className="description">Centrálne registre</HeadingThree>
              </div>
              <ul className="list">
                <li>notársky centrálny register právnických osôb na prijímanie 2% daní z príjmu</li>
                <li>notársky centrálny register závetov</li>
                <li>notársky centrálny register záložných práv</li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}
    </ServiceSC>
  );
};

ServiceSection.propTypes = {
  showFull: PropTypes.bool,
};

ServiceSection.defaultProps = {
  showFull: true,
};

export default ServiceSection;
