import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { COLOR_BLACK_1, COLOR_PRIMARY_WHITE_1 } from '../../constants';

export const HeadingTwoSC = styled.h2`
  ${(props) => {
    if (props.type === 'reversed')
      return `
        color: ${COLOR_PRIMARY_WHITE_1};
        font-size: 44px;
        line-height: 56px;
      `;
    if (props.type === 'primary')
      return `
        color: ${COLOR_BLACK_1};
        font-size: 40px;
        line-height: 50px;
      `;
  }}

  margin-bottom: 20px;
  font-weight: 400;
  font-family: 'PT Serif', serif;
`;

const HeadingTwo = (props) => {
  const { children, className, type } = props;
  const customClass = classNames({ [className]: className !== undefined });

  return (
    <HeadingTwoSC className={customClass} type={type}>
      {children}
    </HeadingTwoSC>
  );
};

HeadingTwo.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
};

HeadingTwo.defaultProps = {
  className: undefined,
  type: 'primary',
};

export default HeadingTwo;
