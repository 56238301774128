import React from 'react';

import NavigationSC from './navigation.styled';

import { NavLink } from 'react-router-dom';

import {
  ROUTE_HOME,
  ROUTE_ABOUT,
  ROUTE_SERVICES_AND_LEGISLATIVE,
  ROUTE_GALLERY,
  ROUTE_CONTACT,
} from '../../constants';

const Navigation = () => {
  return (
    <NavigationSC>
      <NavLink exact className="nav-link" activeClassName="active" to={ROUTE_HOME}>
        Domov
      </NavLink>
      <NavLink exact className="nav-link" activeClassName="active" to={ROUTE_ABOUT}>
        O nás
      </NavLink>
      <NavLink
        exact
        className="nav-link"
        activeClassName="active"
        to={ROUTE_SERVICES_AND_LEGISLATIVE}
      >
        Služby a legislatíva
      </NavLink>
      <NavLink exact className="nav-link" activeClassName="active" to={ROUTE_GALLERY}>
        Galéria
      </NavLink>
      {/*<NavLink exact className="nav-link" activeClassName="active" to={ROUTE_NEWS}>*/}
      {/*  Novinky*/}
      {/*</NavLink>*/}
      <NavLink exact className="nav-link" activeClassName="active" to={ROUTE_CONTACT}>
        Kontakt
      </NavLink>
      {/*<LangSwitcher />*/}

      {/*<div className="nav-links">
         <BodyText>Domov</BodyText>
        <BodyText color="description">O nás</BodyText>
        <BodyText color="description">Služby a legislatíva</BodyText>
        <BodyText color="description">Novinky</BodyText>
        <BodyText color="description">Kontakt</BodyText>

      </div> */}
    </NavigationSC>
  );
};

export default Navigation;
