import styled from 'styled-components';
import { bpSmallMax } from '../../assets/styles/breakpoints';

export default styled.div`
  height: fit-content;

  margin: 150px auto 50px 135px;

  .slider-screen {
    display: block;
  }

  .slider-mobile {
    display: none;
  }

  .slick-slide {
    padding-right: 30px;
  }

  .slides-counter {
    background-color: #eff2f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 221px;
    height: 81px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 0;
  }

  .button {
    background-color: transparent;
    border: none;
    :focus {
      outline: 0;
    }
  }

  .counting {
    margin: 0;
  }

  image {
    width: 70%;
    height: auto;
    max-height: 580px;
  }

  @media (max-width: ${bpSmallMax}px) {
    margin-left: 0px;

    .slick-slide {
      padding-right: 0px;
    }

    .slider-screen {
      display: none;
    }

    .slider-mobile {
      display: block;
    }
  }
`;
