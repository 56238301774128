import React from 'react';
import ExperienceSC from './experience-section.styled';
import ExperienceImage from './experience-image';
import HeadingTwo from '../typography/heading-two';
import BodyText from '../typography/body-text';
import Button from '../typography/button';
import LogoOneImage from './logo-one-image';
import LogoTwoImage from './logo-two-image';
import LogoThreeImage from './logo-three-image';

import { ROUTE_ABOUT } from '../../constants';

import { NavLink } from 'react-router-dom';

const ExperienceSection = () => {
  return (
    <ExperienceSC>
      <div className="row">
        <div className="left-side">
          <div className="text">
            <HeadingTwo>Bohaté skúsenosti</HeadingTwo>
            <BodyText>
              Vítam Vás na stránkach môjho notárskeho úradu so sídlom v Šamoríne. Notárom som už od
              roku 2006 a som Vám ja ako aj môj notársky kolektív k dispozícii každý pracovný deň.
              Neváhajte sa na nás obrátiť so žiadosťou o právnu pomoc, nakoľko notár v postavení
              verejného činiteľa je zárukou kvalitného výkonu právnickej profesie a preto budem
              postupovať s odbornou starostlivosťou a maximálnou obozretnosťou, profesionalitou a
              kvalitou.
            </BodyText>
            <NavLink exact className="nav-link" activeClassName="active" to={ROUTE_ABOUT}>
              <Button className="button">Chcem vedieť viac</Button>
            </NavLink>
          </div>
        </div>
        <div className="right-side">
          <ExperienceImage />
        </div>
      </div>
      <div className="bottom-side">
        <div className="logos">
          <LogoOneImage />
          <LogoTwoImage />
          <LogoThreeImage />
        </div>
      </div>
    </ExperienceSC>
  );
};

export default ExperienceSection;
