import styled from 'styled-components';
import { COLOR_BLUE_1, COLOR_STRIPE_GREY_1 } from '../../../../constants';

export default styled.div`
  background-color: ${COLOR_STRIPE_GREY_1};
  height: 50px;
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;

  .inner {
    max-width: 956px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    a {
      color: ${COLOR_BLUE_1};
      font-size: 14px;
      line-height: 1.43;
    }
  }
`;
