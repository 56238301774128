import styled from 'styled-components';
import Map2x from '../../assets/img/jpg/map@2x.jpg';

export default styled.section`
  width: 100%;
  height: 500px;
  margin: 150px auto 0 auto;
  position: relative;
  cursor: pointer;

  background-image: url(${Map2x});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .address {
    position: absolute;
    width: 100%;
    top: -80px;

    &__inner {
      max-width: 1200px;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 349px) {
    margin: 200px auto 0 auto;
  }
`;
