import React from 'react';
import { withRouter } from 'react-router-dom';

import HeaderSC from './header.styled';
import LogoImage from './logo-image';

import Navigation from '../../navigation';
import NavigationMobile from '../../navigation-mobile';

const Header = (props) => {
  const {
    history: { push },
  } = props;

  return (
    <HeaderSC>
      <div className="logo" onClick={() => push('/')}>
        <LogoImage />
      </div>
      <div className="navbar">
        <Navigation />
      </div>
      <div className="navbar-small">
        <NavigationMobile />
      </div>

      {/*<div className="nav-links">
         <BodyText>Domov</BodyText>
        <BodyText color="description">O nás</BodyText>
        <BodyText color="description">Služby a legislatíva</BodyText>
        <BodyText color="description">Novinky</BodyText>
        <BodyText color="description">Kontakt</BodyText>

      </div> */}
    </HeaderSC>
  );
};

export default withRouter(Header);
