import React from 'react';
import MapSC from './map-section.styled';
import HeadingTwo from '../typography/heading-two';

const handleMapClick = () => {
  window.open(process.env.REACT_APP_MAP, '_blank');
};

const MapSection = () => {

  return (
    <MapSC onClick={handleMapClick}>
      <div className="address">
        <div className="address__inner">
          <HeadingTwo>
            Samaria Centrum <br />
            Bratislavská 100/C <br />
            931 01 Šamorín
          </HeadingTwo>
        </div>

      </div>
    </MapSC>
  );
};

export default MapSection;
