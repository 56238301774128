import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: auto;

  img {
    /* DEFAULT image */
    display: block;
    margin-left: auto;
    width: 100%;
    height: 100%;
    position: relative;
  }
`;
