import styled from 'styled-components';
import {
  COLOR_BLUE_GREY_1,
  COLOR_GREY_1,
  COLOR_WHITE_BLUE_1,
  COLOR_BLACK_1,
} from '../../constants';
import { bpMediumMax, bpXLargeMax, bpMediumMin } from '../../assets/styles/breakpoints';

export default styled.section`
  margin-left: 135px;
  margin-right: 135px;
  height: fit-content;

  .top-side {
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .services-text {
      margin-bottom: 10px;
      margin-top: auto;
    }

    .services-button {
      margin-bottom: 30px;
      margin-top: auto;
    }
  }

  .bottom-side {
    display: flex;
    width: 100%;
    height: 510px;

    .left {
      background-color: ${COLOR_BLUE_GREY_1};
      height: 100%;
      width: calc(100% / 3);

      .column {
        margin: 0 50px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .description-container {
          border-bottom: 1px #314043 solid;
          height: 170px;

          .description {
            margin-top: 50px;
          }
        }

        .list {
          margin: 30px 0 30px 0;
          font-family: 'PT Sans', sans-serif;
          font-size: 18px;
          line-height: 1.94;
          color: ${COLOR_BLACK_1};
          padding: 0 20px;
        }
      }
    }

    .middle {
      background-color: ${COLOR_GREY_1};
      height: 100%;
      width: calc(100% / 3);

      .column {
        margin: 0 50px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .description-container {
          border-bottom: 1px #314043 solid;
          height: 170px;

          .description {
            margin-top: 50px;
          }
        }

        .list {
          margin: 30px 0 30px 0;
          font-family: 'PT Sans', sans-serif;
          font-size: 18px;
          line-height: 1.94;
          color: ${COLOR_BLACK_1};
          padding: 0 20px;
        }
      }
    }

    .right {
      background-color: ${COLOR_WHITE_BLUE_1};
      height: 100%;
      width: calc(100% / 3);

      .column {
        margin: 0 50px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .description-container {
          border-bottom: 1px #314043 solid;
          height: 170px;

          .description {
            margin-top: 50px;
          }
        }

        .list {
          margin: 30px 0 30px 0;
          font-family: 'PT Sans', sans-serif;
          font-size: 18px;
          line-height: 1.94;
          color: ${COLOR_BLACK_1};
          padding: 0 20px;
        }
      }
    }
  }

  .second-row {
    border-top: 1px #314043 solid;
  }

  @media (max-width: 1180px) {
    margin: 30px 0;
    padding-left: 30px;
    padding-right: 30px;

    .services-button {
      display: none;
    }

    .bottom-side {
      height: 100%;
      flex-direction: column;
      .left,
      .middle,
      .right {
        height: 100%;
        width: 100%;

        .column {
          padding-left: 15px;
          padding-right: 15px;
          margin: 0;

          .description-container {
            height: auto;
          }
        }
      }

      .left {
      }
    }
  }

  @media (min-width: 1180px) and (max-width: ${bpXLargeMax}px) {
    .bottom-side {
      .left,
      .middle,
      .right {
        .column {
          margin: 0 15px;
        }
      }
    }
  }
`;
