import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { COLOR_BLACK_1, COLOR_PRIMARY_WHITE_1 } from '../../constants';

export const HeadingOneSC = styled.h1`
  ${(props) => {
    if (props.type === 'reversed')
      return `
        color: ${COLOR_PRIMARY_WHITE_1};
        font-size: 55px;
        line-height: 64px;
      `;
    if (props.type === 'primary')
      return `
        color: ${COLOR_BLACK_1};
        font-size: 60px;
        line-height: 75px;
        font-weight: 400;
      `;
  }}

  margin-bottom: 30px;
  font-family: 'PT Serif', serif;
`;

const HeadingOne = (props) => {
  const { children, className, type } = props;
  const customClass = classNames({ [className]: className !== undefined });

  return (
    <HeadingOneSC className={customClass} type={type}>
      {children}
    </HeadingOneSC>
  );
};

HeadingOne.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
};

HeadingOne.defaultProps = {
  className: undefined,
  type: 'primary',
};

export default HeadingOne;
