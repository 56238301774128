import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import {
  COLOR_PRIMARY_BLACK_1,
  COLOR_SUCCESS_1,
  COLOR_ERROR_1,
  COLOR_DESCRIPTION_1,
} from '../../constants';

export const BodyTextSC = styled.p`
  ${(props) => {
    if (props.color) {
      if (props.color === 'success') return `color: ${COLOR_SUCCESS_1};`;
      if (props.color === 'error') return `color: ${COLOR_ERROR_1};`;
      if (props.color === 'description') return `color: ${COLOR_DESCRIPTION_1};`;
      if (props.color === 'normal') return `color: ${COLOR_PRIMARY_BLACK_1};`;
    }
  }}

  ${(props) => {
    if (props.size) {
      if (props.size === 'small') {
        return `font-size: 14px; line-height: 20px; margin-bottom: 20px;`;
      }
      if (props.size === 'normal') {
        return `font-size: 18px; line-height: 35px; margin-bottom: 30px;`;
      }
    }
  }}

font-family: 'PT Sans', sans-serif;
`;

const BodyText = (props) => {
  const { children, className, size, color } = props;
  const customClass = classNames({ [className]: className !== undefined });

  /* processing custom props */
  const scProps = {};
  if (size) {
    scProps.size = size;
  }

  if (color) {
    scProps.color = color;
  }

  return (
    <BodyTextSC className={customClass} {...scProps}>
      {children}
    </BodyTextSC>
  );
};

BodyText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
};

BodyText.defaultProps = {
  className: undefined,
  color: 'normal',
  size: 'normal',
};

export default BodyText;
