import styled from 'styled-components';

export default styled.div`
  img {
    /* DEFAULT image */
    display: block;
    height: 67px;
    width: auto;
    position: relative;
  }
`;
