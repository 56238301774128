import React from 'react';

import FooterSC from './footer.styled';
import BodyTextReversed from '../../typography/body-text-reversed';
import HeadingTwo from '../../typography/heading-two';
import Button from '../../typography/button';

import { NavLink } from 'react-router-dom';

import {
  ROUTE_HOME,
  ROUTE_ABOUT,
  ROUTE_SERVICES_AND_LEGISLATIVE,
  ROUTE_CONTACT,
} from '../../../constants';

const Footer = () => {
  return (
    <FooterSC>
      <div className="footer-content">
        <div className="contact-us">
          <div className="contact-us-text">
            <HeadingTwo type="reversed">
              Sme tu pre vás, neváhajte nás kontaktovať
            </HeadingTwo>
          </div>
          <div className="appointment-button">
            <NavLink exact className="nav-link" activeClassName="active" to={ROUTE_CONTACT}>
              <Button type="primary-reversed">Dohodnúť stretnutie</Button>
            </NavLink>
          </div>
        </div>
        <div className="bottom-navbar">
          <BodyTextReversed color="description" className="text">
            Všetky práva vyhradené JUDr. PhDr. Rudolf Durdík
          </BodyTextReversed>
          <div className="nav-links">
            <NavLink exact className="nav-link" activeClassName="active" to={ROUTE_HOME}>
              Domov
            </NavLink>
            <NavLink exact className="nav-link" activeClassName="active" to={ROUTE_ABOUT}>
              O nás
            </NavLink>
            <NavLink
              exact
              className="nav-link"
              activeClassName="active"
              to={ROUTE_SERVICES_AND_LEGISLATIVE}
            >
              Služby a legislatíva
            </NavLink>
            {/*<NavLink exact className="nav-link" activeClassName="active" to={ROUTE_NEWS}>
              Novinky
  </NavLink>*/}
            <NavLink exact className="nav-link" activeClassName="active" to={ROUTE_CONTACT}>
              Kontakt
            </NavLink>
          </div>
        </div>
      </div>
    </FooterSC>
  );
};

export default Footer;
