import React, { useState } from 'react';

import NavigationMobileSC from './navigation-mobile.styled';

import { NavLink } from 'react-router-dom';

import MenuIcon from './menu-icon';
import CloseIcon from './close-icon';

import {
  ROUTE_HOME,
  ROUTE_ABOUT,
  ROUTE_GALLERY,
  ROUTE_SERVICES_AND_LEGISLATIVE,
  ROUTE_CONTACT,
} from '../../constants';

const NavigationMobile = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMobileMenuClick = () => {
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  return (
    <NavigationMobileSC>
      <div>
        {!isMenuOpen && (
          <div>
            <button onClick={toggleMenu} className="menu-button">
              <MenuIcon />
            </button>
          </div>
        )}

        {isMenuOpen && (
          <div className="menu-open">
            <div className="close-button">
              <button onClick={toggleMenu}>
                <CloseIcon />
              </button>
            </div>
            <div className="nav-links">
              <NavLink
                exact
                className="nav-link"
                activeClassName="active"
                to={ROUTE_HOME}
                onClick={handleMobileMenuClick}
              >
                Domov
              </NavLink>
              <NavLink
                exact
                className="nav-link"
                activeClassName="active"
                to={ROUTE_ABOUT}
                onClick={handleMobileMenuClick}
              >
                O nás
              </NavLink>

              <NavLink
                exact
                className="nav-link"
                activeClassName="active"
                to={ROUTE_SERVICES_AND_LEGISLATIVE}
                onClick={handleMobileMenuClick}
              >
                Služby a legislatíva
              </NavLink>
              {/*<NavLink*/}
              {/*  exact*/}
              {/*  className="nav-link"*/}
              {/*  activeClassName="active"*/}
              {/*  to={ROUTE_NEWS}*/}
              {/*  onClick={handleMobileMenuClick}*/}
              {/*>*/}
              {/*  Novinky*/}
              {/*</NavLink>*/}
              <NavLink
                exact
                className="nav-link"
                activeClassName="active"
                to={ROUTE_GALLERY}
                onClick={handleMobileMenuClick}
              >
                Galéria
              </NavLink>
              <NavLink
                exact
                className="nav-link"
                activeClassName="active"
                to={ROUTE_CONTACT}
                onClick={handleMobileMenuClick}
              >
                Kontakt
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </NavigationMobileSC>
  );
};

export default NavigationMobile;
