import styled from 'styled-components';
import { COLOR_PRIMARY_WHITE_1 } from '../../../constants';

import { bpMediumMax } from '../../../assets/styles/breakpoints';

export default styled.header`
  height: 100px;
  width: auto;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLOR_PRIMARY_WHITE_1};
  border-bottom: 1px #e2e8ed solid;

  .logo {
    cursor: pointer;
  }

  .navbar {
    width: 50%;
    height: 100%;
  }

  .navbar-small {
    height: 100%;
    display: none;
  }

  @media (max-width: ${bpMediumMax}px) {
    .navbar {
      display: none;
    }
    .navbar-small {
      display: block;
    }
  }

  @media (max-width: 1230px) {
    margin: 0 30px;
  }
`;
