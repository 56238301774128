import React from 'react';
import TeamSC from './team-section.styled';
import HeadingTwo from '../typography/heading-two';
import TeamMember from './team-member';

const TeamSection = ({ title }) => {
  return (
    <TeamSC>
      <div className="title-container">
        <HeadingTwo className="title">{title}</HeadingTwo>
      </div>
      <div className="team-list">
        <TeamMember />
      </div>
    </TeamSC>
  );
};

export default TeamSection;
