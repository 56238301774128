import React from 'react';
import LogoImageSC from './logo-image.styled';
import Logo from '../../../../assets/img/png/logo@3x.png';

export default () => {
  return (
    <LogoImageSC>
      <img src={Logo} alt="Logo" />
    </LogoImageSC>
  );
};
