import styled from 'styled-components';
import { COLOR_BLACK_1 } from '../../constants';
import { bpMediumMax } from '../../assets/styles/breakpoints';

export default styled.div`
  max-width: 1440px;
  margin: 0 auto;
  button {
    display: none;
  }

  .why {
    margin: 100px auto 0 auto;
    max-width: 1230px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;

    .left {
      width: 50%;
    }

    .right {
      padding-left: 15px;
      width: 50%;
    }

    .box {
      margin-top: 30px;
      border-bottom: 1px solid rgb(226, 232, 237);

      p {
        font-size: 18px;
        line-height: 1.94;
        color: ${COLOR_BLACK_1};
      }
    }
  }

  @media (max-width: ${bpMediumMax}px) {
    .why {
      flex-direction: column;
      .left,
      .right {
        width: 100%;
        padding-left: 0px;
      }
    }

    .why > .left {
      flex-direction: column;
    }

    .why > .right {
      flex-direction: column;
    }
  }
`;
