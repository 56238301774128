import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  .nav-link {
    color: rgba(152, 159, 161, 1);
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;

    &.active {
      border-bottom: 1px #314043 solid;
      margin-bottom: -1px;
    }
  }
`;
