import React, { useState, Children } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import dlt from '../translations/en.json';
import { LS_CURRENT_LANGUAGE } from '../constants.js';

export const StateContext = React.createContext();

const StateProvider = (props) => {
  const { children } = props;
  const [currentLang, setCurrentLang] = useState(localStorage.getItem(LS_CURRENT_LANGUAGE));

  return (
    <StateContext.Provider
      value={{
        dlt,                    // default language templated a JSON array
        currentLang,            // current language
        setCurrentLang         // language switch function
      }}
    >
      {Children.only(children)}
    </StateContext.Provider>
  );
};

/**
 * If you want to use HOC... 
 * @param {*} WrappedComponent
 */
export const withContext = (WrappedComponent) => (props) => {
  return (
    <StateContext.Consumer>
      {(context) => <WrappedComponent {...props} context={context} />}
    </StateContext.Consumer>
  );
};

export default withRouter(StateProvider);

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
