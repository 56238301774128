import React from 'react';
import RightArrowSC from './right-arrow.styled';
import RightArrow from './../../../assets/img/svg/arrow-right.svg';

export default () => {
  return (
    <RightArrowSC>
      <img src={RightArrow} alt="" />
    </RightArrowSC>
  );
};
