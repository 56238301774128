import React from 'react';
import TeamMemberSC from './team-member.styled';

import BodyText from '../../typography/body-text';

class TeamMember extends React.Component {
  constructor() {
    super();

    this.state = {
      team: [
        {
          id: 0,
          name: 'JUDr. PhDr. Rudolf Durdík',
          position: 'notár',
        },
        {
          id: 1,
          name: 'JUDr. Lenka Luptáková',
          position: 'kandidát',
        },
        {
          id: 2,
          name: 'JUDr. Melinda Lunák',
          position: 'koncipient',
        },
        {
          id: 3,
          name: 'JUDr. Alena Durdík',
          position: 'koncipient',
        },
        {
          id: 4,
          name: 'Bc. Barbora Galgóciová',
          position: 'administratívny zamestnanec',
        },
      ],
    };
  }

  render() {
    return (
      <TeamMemberSC>
        <ul>
          {this.state.team.map((team) => (
            <li key={team.id}>
              <BodyText>
                {team.name} - <span className="position">{team.position}</span>
              </BodyText>
            </li>
          ))}
        </ul>
      </TeamMemberSC>
    );
  }
}

export default TeamMember;
