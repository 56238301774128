import React from 'react';
import LogoOneImageSC from './logo-one-image.styled';
import LogoOne from '../../../assets/img/jpg/logo1.jpg';

export default () => {
  return (
    <LogoOneImageSC>
      <a href="https://www.notar.sk/" target="_blank" rel="noopener noreferrer">
        <img src={LogoOne} alt="Logo one" className="logo-one" />
      </a>
    </LogoOneImageSC>
  );
};
