import React from 'react';
import { Router } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { createBrowserHistory } from 'history';
import BSReboot from './assets/styles/bootstrap-reboot';
import GlobalStyles from './assets/styles/global-app-styles';
import Layout from './components/layout';
import StateProvider from './context/context';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';

import ScrollToTop from './utils/scroll-top';

const history = createBrowserHistory();
const GlobalStyle = createGlobalStyle`
    ${BSReboot}
    ${GlobalStyles}
  `;

const consentApproved = Cookies.get('CookieConsent');
console.log(consentApproved);

const HelmetHead = () => {
  const gaid = 'GTM-T3QJTBN';
  if (process.env.REACT_APP_IS_DEVELOPMENT === 'false' && consentApproved) {
    return (
      <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaid}`} />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', '${gaid}');
          `}
        </script>
      </Helmet>
    );
  }
  return null;
};

const App = () => {
  return (
    <Router history={history}>
      <HelmetHead />
      <ScrollToTop />
      <GlobalStyle />
      <StateProvider>
        <Layout />
      </StateProvider>
    </Router>
  );
};

export default App;
