import styled from 'styled-components';

export const CookieConsentSC = styled.div`
  background: rgb(49, 64, 67);
  height: auto;
  display: flex;
  width: 100%;
  bottom: 0;
  position: fixed;

  .cookieConsent {
    align-items: center !important;
    background: rgb(49, 64, 67) !important;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    left: auto;
    position: relative !important;
    width: 100%;
    z-index: 999;
    height: auto;
    max-width: 1440px;
    bottom: 0;
    margin: 0 auto;

    @media (max-width: 330px) {
      > div {
        max-width: 290px;
      }
    }
  }
`;
