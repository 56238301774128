import React from 'react';
import ExperienceImageSC from './experience-image.styled';

import PicExperience from '../../../assets/img/jpg/experience@1x.jpg';
import PicExperience2x from '../../../assets/img/jpg/experience@2x.jpg';

export default () => {
  return (
    <ExperienceImageSC>
      <picture>
        <source media="(min-width: 576px)" srcSet={`${PicExperience2x} 2x, ${PicExperience} 1x`} />
        {/* <source srcSet={`${PicExperienceMobile3x} 3x, ${PicExperienceMobile2x} 2x, ${PicExperienceMobile} 1x`} /> */}
        <img src={PicExperience} alt="ddd" />
      </picture>
    </ExperienceImageSC>
  );
};
