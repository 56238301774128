import React from 'react';

import NewsDetailPageSC from './news-detail-page.styled';
import NewsImageJpg from '../../assets/img/jpg/IMG_6.jpg';

import HeadingOne from '../../components/typography/heading-one';
import ContentWrapper from './components/content-wrapper';

import BackBar from './components/back-bar';
import ArticleMeta from './components/article-meta';

const NewsDetailPage = () => {
  return (
    <NewsDetailPageSC>
      <BackBar />
      <ContentWrapper>
        <HeadingOne className="news-title">
          Čo je bezpodielové spoluvlastníctvo manželov (BSM)? Nadpis H1
        </HeadingOne>
      </ContentWrapper>

      <ContentWrapper>
        <ArticleMeta author="JUDr. PhDr. Rudolf Durdík" date="12. november 2019" />
      </ContentWrapper>

      <ContentWrapper className="wysiwyg-content">
        <h2>Nadpis H2</h2>
        <p>
          Suspendisse tellus nisl, semper dignissim pretium eu, tristique at lorem. Nam vel
          venenatis lorem, a placerat nisl.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Mauris nec facilisis dolor. Suspendisse tellus nisl, semper dignissim pretium eu,
          tristique at lorem. Nam vel venenatis lorem, a placerat nisl. Ut et nibh eget sapien
          porttitor dictum ac at lacus.{' '}
          <a href="https://www.google.com">Quisque gravida diam, ac ornare nunc mattis non.</a>
        </p>

        <p>
          Suspendisse tellus nisl, semper dignissim pretium eu, tristique at lorem. Nam vel
          venenatis lorem, a placerat nisl.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Mauris nec facilisis dolor. Suspendisse tellus nisl, semper dignissim pretium eu,
          tristique at lorem. Nam vel venenatis lorem, a placerat nisl. Ut et nibh eget sapien
          porttitor dictum ac at lacus. Quisque gravida pretium diam, ac ornare nunc mattis non.{' '}
        </p>
        <img src={NewsImageJpg} alt="durdik" />
        <p className="img-alt">
          Suspendisse tellus nisl, semper dignissim pretium eu, tristique at lorem. Nam vel
          venenatis lorem, a placerat nisl.
        </p>
        <h3>Prečo som sa vrátil na Slovensko H3</h3>
        <p>
          Suspendisse tellus nisl, semper dignissim pretium eu, tristique at lorem. Nam vel
          venenatis lorem, a placerat nisl.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Mauris nec facilisis dolor. Suspendisse tellus nisl, semper dignissim pretium eu,
          tristique at lorem. Nam vel venenatis lorem, a placerat nisl. Ut et nibh eget sapien
          porttitor dictum ac at lacus. Quisque gravida pretium diam, ac ornare nunc mattis non.
        </p>
        <p>
          Suspendisse tellus nisl, semper dignissim pretium eu, tristique at lorem. Nam vel
          venenatis lorem, a placerat nisl.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Mauris nec facilisis dolor. Suspendisse tellus nisl, semper dignissim pretium eu,
          tristique at lorem. Nam vel venenatis lorem, a placerat nisl. Ut et nibh eget sapien
          porttitor dictum ac at lacus. Quisque gravida pretium diam, ac ornare nunc mattis non.
        </p>
        <ul>
          <li>Suspendisse tellus nisl, semper dignissim pretium eu, tristique at lorem.</li>
          <li>Suspendisse tellus nisl, semper dignissim pretium eu, tristique at lorem.</li>
          <li>Suspendisse tellus nisl, semper dignissim pretium eu, tristique at lorem.</li>
          <li>Suspendisse tellus nisl, semper dignissim pretium eu, tristique at lorem.</li>
        </ul>
      </ContentWrapper>
    </NewsDetailPageSC>
  );
};

export default NewsDetailPage;
