import Image2 from '../../../../assets/img/jpg/IMG_2@2x.jpg';
import Image3 from '../../../../assets/img/jpg/IMG_3@2x.jpg';
import Image4 from '../../../../assets/img/jpg/IMG_4@2x.jpg';
import Image7 from '../../../../assets/img/jpg/IMG_7@2x.jpg';

const NEWS_DATA = [
  {
    id: 1,
    image: Image2,
    title: 'Zákon o registri partner verejného sektora',
    date: '12. november 2019',
  },
  {
    image: Image3,
    title: 'Zákon o registri partner verejného sektora',
    date: '12. november 2019',
    id: 2,
  },
  {
    image: Image4,
    title: 'Zákon o registri partner verejného sektora',
    date: '12. november 2019',
    id: 3,
  },
  {
    image: Image3,
    title: 'Zákon o registri partner verejného sektora',
    date: '12. november 2019',
    id: 4,
  },
  {
    image: Image7,
    title: 'Zákon o registri partner verejného sektora',
    date: '12. november 2019',
    id: 5,
  },
  {
    image: Image2,
    title: 'Zákon o registri partner verejného sektora',
    date: '12. november 2019',
    id: 6,
  },
  {
    image: Image2,
    title: 'Zákon o registri partner verejného sektora',
    date: '12. november 2019',
    id: 7,
  },
  {
    image: Image3,
    title: 'Zákon o registri partner verejného sektora',
    date: '12. november 2019',
    id: 8,
  },
  {
    image: Image4,
    title: 'Zákon o registri partner verejného sektora',
    date: '12. november 2019',
    id: 9,
  },
];

export default NEWS_DATA;
