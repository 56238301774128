import React from 'react';
import BiographySC from './biography-section.styled';

import HeadingTwo from '../typography/heading-two';

import BiographyList from './biography-list';

const BiographySection = ({ title }) => {
  return (
    <BiographySC>
      <div className="title-container">
        <HeadingTwo className="title">{title}</HeadingTwo>
      </div>
      <div className="document-list">
        <BiographyList />
      </div>
    </BiographySC>
  );
};

export default BiographySection;
