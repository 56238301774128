import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { COLOR_DARK_GREY_1 } from '../../../constants';

const ArticleMetaSC = styled.div`
  margin-top: 30px;

  span {
    color: ${COLOR_DARK_GREY_1};
    font-size: 14px;
    font-family: 'PTSans', sans-serif;

    &:nth-of-type(2) {
      margin-left: 20px;
    }
  }
`;

const ArticleMeta = ({ date, author }) => {
  return (
    <ArticleMetaSC>
      <span>{date}</span>
      <span>{author}</span>
    </ArticleMetaSC>
  );
};

ArticleMeta.propTypes = {
  date: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};

export default ArticleMeta;
