// Local Storage
export const LS_CURRENT_LANGUAGE = 'current_language';

// Routes
export const ROUTE_HOME = '/';
export const ROUTE_ABOUT = '/about';
export const ROUTE_GALLERY = '/gallery';
export const ROUTE_SERVICES_AND_LEGISLATIVE = '/services-and-legislation';
export const ROUTE_NEWS = '/news';
export const ROUTE_CONTACT = '/contact';
export const ROUTE_NEWS_DETAIL = '/news-detail';

// Colors
export const COLOR_BLACK_1 = 'rgba(49, 64, 67, 1)';
export const COLOR_PRIMARY_BLACK_1 = 'rgba(43, 56, 59, 1)';
export const COLOR_PRIMARY_WHITE_1 = 'rgba(255, 255, 255, 1)';
export const COLOR_LIGHTBLUE_1 = 'rgba(225, 232, 234, 1)';
// TODO RESOLVE
export const COLOR_BORDER_BLUEGREY_1 = 'rgba(64, 85, 89, 1)';
export const COLOR_LIGHTGREY_1 = 'rgba(228, 230, 231, 1)';
export const COLOR_WHITE_02 = 'rgba(255, 255, 255, 0.2)';
export const COLOR_BLUE_1 = 'rgba(98, 127, 157, 1)';
// TODO RESOLVE
export const COLOR_BLUEGREY_1 = 'rgba(86, 113, 118, 1)';
export const COLOR_BLUE_GREY_1 = 'rgba(192, 204, 216, 1)';
export const COLOR_DESCRIPTION_1 = 'rgba(148, 156, 158, 1)';
export const COLOR_ERROR_1 = 'rgba(186, 18, 18, 1)';
export const COLOR_GREY_1 = 'rgba(224, 230, 235, 1)';
export const COLOR_DARK_GREY_1 = 'rgba(152, 159, 161, 1)';
export const COLOR_WHITE_BLUE_1 = 'rgba(239, 242, 245, 1)';
export const COLOR_SUCCESS_1 = 'rgba(0, 204, 122, 1)';
export const COLOR_STRIPE_GREY_1 = 'rgba(192, 204, 216)';
