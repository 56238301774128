import { css } from 'styled-components';
import { COLOR_BLACK_1, COLOR_BLUE_1 } from '../../constants';

export default css`
  body {
    font-family: 'PT Serif', serif;
  }

  h2 {
    font-size: 40px;
    line-height: 1.25;
    color: ${COLOR_BLACK_1};
    margin-bottom: 20px;
    font-weight: 400;
  }

  h3 {
    font-size: 27px;
    line-height: 1.48;
    color: ${COLOR_BLACK_1};
    margin-bottom: 20px;
  }

  p {
    font-family: 'PT Sans', sans-serif;
    font-size: 18px;
    line-height: 1.94;
    color: ${COLOR_BLACK_1};
    margin-bottom: 30px;
  }

  a {
    color: ${COLOR_BLUE_1};
    text-decoration: underline;

    &:hover {
      color: ${COLOR_BLUE_1};
      text-decoration: none;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  ol,
  ul {
    padding-left: 30px !important;
    li {
      line-height: 1.95;
      font-size: 18px;
      &::before {
        width: 5px;
      }
    }
  }

  button {
    border-width: 0;
  }

  button:focus {
    outline: 0;
  }
  input:focus {
    outline: 0;
  }
  *:disabled {
    pointer-events: none;
  }
`;
