import React from 'react';

import GalleryPageSC from './gallery-page.styled';
import PageTitle from '../../components/page-title';
import AboutDecorImage3x from '../../assets/img/png/o-nas-decor@3x.png';

import GallerySection from '../../components/gallery-section';

const GalleryPage = () => {
  return (
    <GalleryPageSC>
      <PageTitle title="Galéria" background={AboutDecorImage3x} />
      <GallerySection />
    </GalleryPageSC>
  );
};

export default GalleryPage;
