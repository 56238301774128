import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { CookieConsentSC } from './layout.styled.js';

import messages_sk from '../../translations/sk.json';
import messages_en from '../../translations/en.json';

import { StateContext } from '../../context/context';
import {
  ROUTE_HOME,
  ROUTE_ABOUT,
  ROUTE_SERVICES_AND_LEGISLATIVE,
  ROUTE_GALLERY,
  ROUTE_NEWS,
  ROUTE_CONTACT,
  ROUTE_NEWS_DETAIL,
} from '../../constants';
import Header from './header';
import Footer from './footer';
import MapSection from '../map-section';
import HomePage from '../../pages/home';
import AboutPage from '../../pages/about';
import ServicesAndLegislationPage from '../../pages/services-and-legislation';
import GalleryPage from '../../pages/gallery';
import NewsPage from '../../pages/news';
import ContactPage from '../../pages/contact';
import NewsDetailPage from '../../pages/news-detail/news-detail-page.jsx';

import CookieConsent from 'react-cookie-consent';
import BodyTextReversed from '../typography/body-text-reversed';

const messages = {
  sk: messages_sk,
  en: messages_en,
};

const Layout = () => {
  const { currentLang } = useContext(StateContext);
  return (
    <IntlProvider locale={currentLang} messages={messages[currentLang]}>
      <Header />
      <Switch>
        <Route exact path={ROUTE_HOME}>
          <HomePage />
        </Route>
        <Route exact path={ROUTE_ABOUT}>
          <AboutPage />
        </Route>
        <Route exact path={ROUTE_SERVICES_AND_LEGISLATIVE}>
          <ServicesAndLegislationPage />
        </Route>
        <Route exact path={ROUTE_GALLERY}>
          <GalleryPage />
        </Route>
        <Route exact path={ROUTE_NEWS}>
          <NewsPage />
        </Route>
        <Route path={ROUTE_CONTACT}>
          <ContactPage />
        </Route>
        <Route path={ROUTE_NEWS_DETAIL}>
          <NewsDetailPage />
        </Route>
      </Switch>
      <MapSection />
      <Footer />
      <CookieConsentSC>
        <CookieConsent
          buttonText="Súhlasím"
          buttonStyle={{
            height: '70px',
            width: '170px',
            borderRadius: '35px',
            backgroundColor: '#FFFFFF',
            color: '#314043',
            fontFamily: 'PT Serif',
            fontSize: '17px',
          }}
        >
          <BodyTextReversed size="cookies-text">
            Na správne fungovanie webovej stránky, prispôsobenie obsahu a reklám, poskytovanie
            funkcií sociálnych médií a analýzu návštevnosti používame súbory cookie. Konkrétne
            informácie o tom, ako cookies používame nájdete na{' '}
            <a
              href={`${process.env.REACT_APP_PUBLIC_URL}/cookie.pdf`}
              target="_blank"
              rel="noopener noreferrer"
            >
              tejto adrese
            </a>
            .
          </BodyTextReversed>
        </CookieConsent>
      </CookieConsentSC>
    </IntlProvider>
  );
};

export default Layout;
