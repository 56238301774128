import styled from 'styled-components';
import { COLOR_DARK_GREY_1 } from '../../../constants';

export default styled.div`
  .position {
    color: ${COLOR_DARK_GREY_1};
    margin-bottom: 20px;
    font-size: 15px;
  }
`;
