import React from 'react';
import MenuIconSC from './menu-icon.styled';
import MenuIcon from './../../../assets/img/svg/menu.svg';

export default () => {
  return (
    <MenuIconSC>
      <img src={MenuIcon} alt="" />
    </MenuIconSC>
  );
};
