export const bpXSmallMax = 575.98;
export const bpXSmallMin = 576;

export const bpSmallMax = 768.98;
export const bpSmallMin = 768;

export const bpMediumMax = 991.98;
export const bpMediumMin = 992;

export const bpLargeMax = 1299.78;
export const bpLargeMin = 1300;

export const bpXLargeMax = 1439.98;
export const bpXLargeMin = 1440;

export const bpXXLargeMax = 1919.98;
export const bpXXLargeMin = 1920;

export const bpXXXLargeMax = 2559.98;
export const bpXXXLargeMin = 2560;
