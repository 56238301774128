import React from 'react';
import LeftArrowSC from './left-arrow.styled';
import LeftArrow from './../../../assets/img/svg/arrow-left.svg';

export default () => {
  return (
    <LeftArrowSC>
      <img src={LeftArrow} alt="" />
    </LeftArrowSC>
  );
};
