import React from 'react';
import ContactSC from './contact-section.styled';

import HeadingTwo from '../../components/typography/heading-two';
import HeadingThree from '../../components/typography/heading-three';
import HeadingFour from '../../components/typography/heading-four';
import BodyText from '../typography/body-text';

const ContactSection = () => {
  return (
    <ContactSC>
      <div className="contact-name">
        <HeadingTwo>
          Notársky úrad <br />
          JUDr. PhDr.
          <br />
          Rudolf Durdík
        </HeadingTwo>
      </div>
      <div className="contact-info">
        {/*<div>
          <HeadingThree>Fakturačné údaje</HeadingThree>
          <BodyText>
            IČO: 31783554 <br />
            IČ DPH: SK 1020189445 <br />
            Banka: Prima banka Slovensko, a.s. <br />
            IBAN : SK63 3100 0000 0040 0064 8809
          </BodyText>
        </div>*/}
        <div>
          <HeadingThree>Kontaktné údaje</HeadingThree>
          <div className="two-cols-info">
            <div className="column">
              <HeadingFour>Email:</HeadingFour>
              <a href="mailto:rudolf.durdik@notar.sk">rudolf.durdik@notar.sk</a>
              <a href="mailto:notar@samorin.com">notar@samorin.com</a>
            </div>
            <div className="column">
              <HeadingFour>Tel.:</HeadingFour>
              <a href="tel:+421315621000">+421 31 562 10 00</a>
              <a href="tel:+421905444667">+421 905 444 667</a>
            </div>
          </div>
          <br />

          <HeadingThree className="open-h">Úradné hodiny</HeadingThree>
          <div className="open-hours">
            <div>
              <BodyText className="first-col">
                Pondelok <br />
                Utorok <br />
                Streda
              </BodyText>
              <BodyText className="second-col">
                8:00-16:00 <br />
                8:00-16:00 <br />
                8:00-18:00
              </BodyText>
            </div>
            <div>
              <BodyText className="third-col">
                Štvrtok <br />
                Piatok <br />
              </BodyText>
              <BodyText>
                8:00-16:00 <br />
                8:00-15:00 <br />
              </BodyText>
            </div>
          </div>
          <HeadingThree className="open-h">Úradné hodiny počas mesiacov JÚL a AUGUST</HeadingThree>
          <div className="open-hours">
            <div>
              <BodyText className="first-col">
                Pondelok <br />
                Utorok <br />
                Streda
              </BodyText>
              <BodyText className="second-col">
                8:00-15:00 <br />
                8:00-15:00 <br />
                8:00-15:00
              </BodyText>
            </div>
            <div>
              <BodyText className="third-col">
                Štvrtok <br />
                Piatok <br />
              </BodyText>
              <BodyText>
                8:00-15:00 <br />
                8:00-14:00 <br />
              </BodyText>
            </div>
          </div>
        </div>
      </div>
    </ContactSC>
  );
};

export default ContactSection;
