import React from 'react';
import { Link } from 'react-router-dom';

import NewsFeedSC from './news-feed.styled';
import BodyText from '../../../../components/typography/body-text';
import { ROUTE_NEWS_DETAIL } from '../../../../constants';

const NewsTile = ({ image, title, date }) => {
  return (
    <NewsFeedSC>
      <div className="news-photo">
        <img className="photo" src={image} alt="news" />
      </div>
      <div className="news-title">
        <Link className="news-link" to={ROUTE_NEWS_DETAIL}>
          {title}
        </Link>
        <BodyText color="description" size="small">
          {date}
        </BodyText>
      </div>
    </NewsFeedSC>
  );
};

export default NewsTile;
