/*import React from 'react';
import DocumentFileSC from './document-file.styled';

const DocumentFile = ({ file }) => {
  return <DocumentFileSC>{file}</DocumentFileSC>;
};

export default DocumentFile; */

import React from 'react';
import BiographyListSC from './biography-list.styled';

import BodyText from '../../typography/body-text';

class BiographyList extends React.Component {
  constructor() {
    super();

    this.state = {
      positions: [
        {
          id: 0,
          name: 'Ministerstvo zahraničných vecí a európskych záležitostí SR',
          position: 'ekonomický diplomat',
          year: '2016 - 2019',
        },
        {
          id: 1,
          name: 'Národná rada SR',
          position: 'asistent poslanca NR SR',
          year: '2012 - 2016 ',
        },
        {
          id: 2,
          name: 'Notársky úrad JUDr. Rudolf Durdík  ',
          position: 'notár',
          year: '2006 - 2016 ',
        },
        {
          id: 3,
          name: 'DURDIK&PARTNERS, s. r.o.',
          position: 'advokát',
          year: '1999 - 2001',
        },
      ],
    };
  }

  render() {
    return (
      <BiographyListSC>
        <ul>
          {this.state.positions.map((position) => (
            <li className="row" key={position.id}>
              <BodyText className="job">{position.name}</BodyText>
              <BodyText size="small" className="position">
                {position.position}
              </BodyText>
              <BodyText size="small" className="year">
                {position.year}
              </BodyText>
            </li>
          ))}
        </ul>
      </BiographyListSC>
    );
  }
}

export default BiographyList;
