import React from 'react';

import PageTitleSC from './page-title.styled';

import HeadingOne from '../typography/heading-one';

const PageTitle = ({ title }) => {
  return (
    <PageTitleSC>
      <div className="title-container">
        <HeadingOne className="title-heading">{title}</HeadingOne>
      </div>
    </PageTitleSC>
  );
};

export default PageTitle;
