import React from 'react';
import InfoSC from './info-section.styled';
import HeadingOne from '../typography/heading-one';
import BodyText from '../typography/body-text';

const InfoSection = () => {
  return (
    <InfoSC>
      <div className="row">
        <div className="left-side">
          <HeadingOne className="name">
            JUDr. PhDr.<br /> Rudolf Durdík
          </HeadingOne>
        </div>
        <div className="right-side">
          <BodyText className="info-text">
            Náš kolektív je kedykoľvek pripravený Vám poradiť, pomôcť a vyriešiť Vaše otázky
            rodinného, obchodného a majetkového charakteru. Zachovávame diskrétnosť, nakoľko sme
            viazaní mlčanlivosťou o všetkých skutočnostiach, o ktorých sa dozvieme pri výkone
            notárskeho povolania.
          </BodyText>
        </div>
      </div>
    </InfoSC>
  );
};

export default InfoSection;
