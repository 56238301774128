import React from 'react';
import AboutUsSC from './about-us-section.styled';

import HeadingTwo from '../../components/typography/heading-two';
import HeadingThree from '../../components/typography/heading-three';
import BodyText from '../typography/body-text';

const AboutUsSection = () => {
  return (
    <AboutUsSC>
      <div className="name">
        <HeadingTwo>
          Notársky úrad <br />
          JUDr. PhDr. <br />
          Rudolf Durdík
        </HeadingTwo>
      </div>
      <div className="about-text">
        <HeadingThree>Prečo som sa vrátil na Slovensko</HeadingThree>
        <BodyText>
          Vítame Vás na stránkach nášho notárskeho úradu so sídlom v Šamoríne, okres Dunajská
          Streda. Vychádzajúc zo Zákona č. 323/1992 o notároch a notárskej činnosti (notársky
          poriadok) a postavenia notára ako verejného činiteľa sme povinní pristupovať ku každému
          s maximálnou obozretnosťou, profesionalitou a kvalitou, ktorá pramení z prapôvodného
          spoločenského a sociálneho postavenia notára ako nositeľa verejnej moci.
        </BodyText>
        <BodyText>
          Náš kolektív je kedykoľvek pripravený Vám poradiť, pomôcť a vyriešiť Vaše otázky
          rodinného, obchodného a majetkového charakteru. Svoje skúsenosti, ktoré siahajú ešte do
          obdobia pred notárskou činnosťou ako advokáta, som sa snažil a snažím sa do dnešného dňa
          odborne a kvalifikovane zveľaďovať a zužitkovať aj počas svojej kariéry notára od roku
          2006. I počas prerušenia výkonu notárskej činnosti v rokoch 2016- 2019 vzhľadom na moje
          pôsobenie v diplomatickej službe na Slovenskom zastupiteľskom úrade so sídlom v Ríme, som
          neprestal byt notárom a svoju profesijnú a odbornú činnosť aktívne som rozvíjal účasťou na
          medzinárodných aktivitách a stretnutiach s vedenením UINL (Medzinárodnej notárskej únie).
        </BodyText>
      </div>
    </AboutUsSC>
  );
};

export default AboutUsSection;
