import styled from 'styled-components';

export default styled.div`
  margin: 0 auto;

  .feed-container {
    display: flex;
    flex-wrap: wrap;
    margin: 100px auto;
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;
