import React from 'react';
import LogoThreeImageSC from './logo-three-image.styled';
import LogoThree from '../../../assets/img/jpg/logo3.jpg';

export default () => {
  return (
    <LogoThreeImageSC>
      <a
        href="http://www.justice.gov.sk/Stranky/default.aspx"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={LogoThree} alt="Logo three" />
      </a>
    </LogoThreeImageSC>
  );
};
