import React from 'react';
import CloseIconSC from './close-icon.styled';
import CloseIcon from './../../../assets/img/svg/close.svg';

export default () => {
  return (
    <CloseIconSC>
      <img src={CloseIcon} alt="" />
    </CloseIconSC>
  );
};
