import React from 'react';

import AboutPageSC from './about-page.styled';

import PageTitle from '../../components/page-title';
import AboutDecorImage3x from '../../assets/img/png/o-nas-decor@3x.png';

import AboutUsSection from '../../components/about-us-section/about-us-section';
import ImagesSection from '../../components/images-section';
import BiographySection from '../../components/biography-section';
import TeamSection from '../../components/team-section';

const AboutPage = () => {
  return (
    <AboutPageSC>
      <PageTitle title="O nás" background={AboutDecorImage3x} />
      <AboutUsSection />
      <TeamSection title="Náš tím" />
      <ImagesSection />
      <BiographySection title="Pracovné skúsenosti" />
    </AboutPageSC>
  );
};

export default AboutPage;
