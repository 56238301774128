import styled from 'styled-components';
import { COLOR_BLACK_1 } from '../../../../constants';

export default styled.section`
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;

  .news-photo {
    width: 100%;
    height: auto;

    .photo {
      width: auto;
      height: 100%;
      max-height: 200px;
    }
  }

  .news-link {
    color: ${COLOR_BLACK_1};
    font-size: 18px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: 'PT Serif', serif;
  }
  
  .news-title {
    height: 150px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-left: 30px;
    border-left: 1px #e2e8ed solid;
  }
`;
