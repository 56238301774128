import React from 'react';

import ContactPageSC from './contact-page.styled';

import PageTitle from '../../components/page-title';
import ContactSection from '../../components/contact-section';

import ContactDecorImage3x from '../../assets/img/png/kontakt-decor@3x.png';

const ContactPage = () => {
  return (
    <ContactPageSC>
      <PageTitle title="Kontakt" background={ContactDecorImage3x} />
      <ContactSection />
    </ContactPageSC>
  );
};

export default ContactPage;
