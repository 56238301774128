import styled from 'styled-components';
import { bpLargeMax, bpMediumMax, bpXSmallMax } from '../../assets/styles/breakpoints';

export default styled.div`
  font-weight: bold;
  margin: 100px auto 0px auto;
  display: flex;
  max-width: 1170px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    .row {
      margin-top: 30px;
      border-bottom: 1px #e2e8ed solid;
    }
  }

  .title-container {
    width: 41%;

    .title {
      max-width: 268px;
    }
  }

  .team-list {
    width: 50%;
    padding-left: 16px;
  }

  @media (max-width: ${bpLargeMax}px) {
    margin: 100px 50px 0 50px;
  }

  @media (max-width: ${bpMediumMax}px) {
    flex-direction: column;
    .title-container {
      width: 100%;
    }
    .team-list {
      width: 100%;
      padding-left: 0;
      margin-top: 20px;
      ul {
        padding-left: 0 !important;
      }
    }
  }

  @media (max-width: ${bpXSmallMax}px) {
    margin: 100px 30px 0 30px;
  }
`;
