import styled from 'styled-components';

import BackroungImage from '../../assets/img/png/error-page.png'
import { COLOR_BLUE_GREY_1 } from '../../constants';

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  .menu-button {
    border: none;
  }
  .menu-open {
    //background-color: rgba(254, 254, 254, 1);
    background-color: ${COLOR_BLUE_GREY_1};
    background-image: url(${BackroungImage});
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100vh;
    display: flex;
    top: 0;
    left: 0;
    flex-direction: column;

    .close-button {
      position: absolute;
      z-index: 110;
      top: 40px;
      right: 30px;

      button {
        background-color: transparent;
        border: none;
      }
    }

    .nav-links {
      margin-top: 150px;
      display: flex;
      flex-direction: column;
      text-align: center;

      .nav-link {
        color: rgba(152, 159, 161, 1);
        font-family: 'PT Sans', sans-serif;
        font-size: 14px;
        text-decoration: none;
        height: 100%;
        padding-bottom: 50px;

        &.active {
          color: #ffffff;
        }
      }
    }
    
  }
`;
