import React from 'react';
import HomePageSC from './home-page.styled';
import InfoSection from '../../components/info-section';
import ExperienceSection from '../../components/experience-section';
import ServiceSection from '../../components/service-section';

const HomePage = () => {
  return (
    <HomePageSC>
      <InfoSection />
      <ExperienceSection />
      <ServiceSection showFull={false} />
      {/*<LawSection />*/}
    </HomePageSC>
  );
};

export default HomePage;
