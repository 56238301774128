import styled from 'styled-components';

export default styled.div`
  img {
    /* DEFAULT image */
    display: block;
    height: 16px;
    width: 12px;
    position: relative;
  }
`;
