import React from 'react';
import Slider from 'react-slick';

import ImageSC from './images-section.styled';
import ImageOne from '../../assets/img/jpg/image_1.jpg';
import ImageTwo from '../../assets/img/jpg/image_2.jpg';
import ImageFour from '../../assets/img/jpg/image_4.jpg';
import ImageFive from '../../assets/img/jpg/image_5.jpg';
import ImageSix from '../../assets/img/jpg/image_6.jpg';
import ImageSeven from '../../assets/img/jpg/image_7.jpg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ArrowLeft from './left-arrow/index';
import ArrowRight from './right-arrow/index';

import BodyText from './../typography/body-text';

const slickSliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1.3,
  slidesToScroll: 1,
  arrows: false,
};

const slickSliderSettingsMobile = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const slidesCnt = 6;

class ImagesSlider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 1,
    };
    this.sliderRef = React.createRef();
    this.sliderRefMobile = React.createRef();
  }

  next = () => {
    const { currentSlide } = this.state;
    if (currentSlide !== slidesCnt) {
      this.setState(
        (state) => ({ ...state, currentSlide: state.currentSlide + 1 }),
        this.sliderRef.current.slickNext(),
        this.sliderRefMobile.current.slickNext()
      );
    }
  };

  previous = () => {
    const { currentSlide } = this.state;
    if (currentSlide !== 1) {
      this.setState(
        (state) => ({ ...state, currentSlide: state.currentSlide - 1 }),
        this.sliderRef.current.slickPrev(),
        this.sliderRefMobile.current.slickPrev()
      );
    }
  };

  render() {
    const { currentSlide } = this.state;

    return (
      <ImageSC>
        <Slider className="slider-mobile" ref={this.sliderRefMobile} {...slickSliderSettingsMobile}>
          <img src={ImageTwo} alt="durdik" />
          <img src={ImageOne} alt="durdik" />
          <img src={ImageFour} alt="durdik" />
          <img src={ImageFive} alt="durdik" />
          <img src={ImageSix} alt="durdik" />
          <img src={ImageSeven} alt="durdik" />
        </Slider>
        <Slider className="slider-screen" ref={this.sliderRef} {...slickSliderSettings}>
          <img src={ImageTwo} alt="durdik" />
          <img src={ImageOne} alt="durdik" />
          <img src={ImageFour} alt="durdik" />
          <img src={ImageFive} alt="durdik" />
          <img src={ImageSix} alt="durdik" />
          <img src={ImageSeven} alt="durdik" />
        </Slider>

        <div className="slides-counter">
          <button className="button" onClick={this.previous}>
            <ArrowLeft />
          </button>
          <BodyText size="small" className="counting">
            {currentSlide} / {slidesCnt}
          </BodyText>

          <button className="button" onClick={this.next}>
            <ArrowRight />
          </button>
        </div>
      </ImageSC>
    );
  }
}

export default ImagesSlider;
