import styled from 'styled-components';

import { bpLargeMax, bpMediumMax, bpXSmallMax } from '../../assets/styles/breakpoints';

export default styled.div`
  display: flex;
  max-width: 1170px;
  margin: 150px auto 100px auto;

  .contact-name {
    width: 50%;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 15px;

    .open-h {
      margin-top: 30px;
    }

    .open-hours {
      display: flex;

      & > div {
        display: flex;

        .first-col {
          margin-right: 27px;
          margin-bottom: 0px;
        }

        .second-col {
          margin-right: 116px;
          margin-bottom: 0px;
        }

        .third-col {
          margin-right: 43.1px;
        }
      }
    }
  }

  .two-cols-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    .column {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
    }
  }

  @media (max-width: ${bpLargeMax}px) {
    margin: 150px 50px 100px 50px;

    .second-col {
      margin-right: 67px !important;
    }
    .two-cols-info {
      flex-direction: column;
      .column {
        margin-top: 20px;
      }
    }
  }

  @media (max-width: ${bpMediumMax}px) {
    flex-direction: column;
    .contact-name {
      width: 100%;
    }
    .contact-info {
      width: 100%;
      padding-left: 0;
    }

    .open-hours {
      flex-direction: column;
    }

    .second-col {
      margin-right: 0px !important;
    }
  }

  @media (max-width: ${bpXSmallMax}px) {
    margin: 150px 30px 100px 30px;
  }
`;
