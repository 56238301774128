import React from 'react';

import ServicesAndLegislationPageSC from './services-and-legislation-page.styled';

import PageTitle from '../../components/page-title';

import ServicesDecorImage3x from '../../assets/img/png/sluzby-decor@3x.png';
import DurdikImageJpg from '../../assets/img/jpg/image_3.jpg';
import ServiceSection from '../../components/service-section';
import HeadingTwo from '../../components/typography/heading-two';

const blabla = [
  {
    head: 'Postavenie, činnosť a samosprávu notárov',
    text:
      'upravuje zákon Slovenskej národnej rady č.323/92 Zb. \n' +
      'o notároch a notárskej činnosti podľa ktorého je',
  },
  {
    head: 'Notár',
    text:
      'štátom určenou osobou vykonávať notársku činnosť a ďalšiu činnosť podľa tohto zákona, \n' +
      'je viazaný len Ústavou Slovenskej republiky, ústavnými zákonmi a zákonmi',
  },
  {
    head: 'Notár',
    text:
      'pri výkone notárskej činnosti má notár postavenie verejného činiteľa a preto vykonávanie notárskej činnosti je výkonom verejnej moci“',
  },
  {
    head: 'Notár',
    text:
      'svoju činnosť vykonáva vlastným menom a na vlastnú zodpovednosť a podlieha štátnemu dohľadu ministra spravodlivosti Slovenskej republiky',
  },
  {
    head: 'Notár',
    text:
      `pri výkone svojej činnosti postupuje nestranne a nezávisle, \n` +
      `je povinný postupovať k výkonu notárskej činnosti s odbornou starostlivosťou, \n` +
      `je povinný odmietnuť vykonať požadovaný úkon, ak odporuje alebo obchádza zákon, prieči sa dobrým mravom`,
  },
  {
    head: 'Notár',
    text:
      'svojím postavením a svojou činnosťou dbá o usporiadanie a istotu v právnych vzťahoch a o predchádzanie sporom',
  },
  {
    head: 'Notársky úrad',
    text:
      'predstavuje súbor právomocí udelených na dobu neurčitú štátom notárovi ako fyzickej osobe na výkon notárskej činnosti a na ďalšiu činnosť podľa tohto zákona, trvalo spojených so sídlom, do ktorého bol notár vymenovaný',
  },
  {
    head: 'Notárskou činnosťou',
    text:
      'je spisovanie a vydávanie listín o právnych úkonoch (§ 46 až 55), osvedčovanie právne významných skutočností (§ 56 až 64), konanie vo veciach notárskych úschov (§ 65 až 73), úkony vo veciach notárskych centrálnych registrov (§ 73a až 73j)',
  },
  {
    head: 'Notár',
    text:
      'pri svojej činnosti môže fyzickým a právnickým osobám poskytovať právne rady, spisovať iné listiny, vykonávať správu majetku a zastupovať ich v súvislosti so správou poskytnúť zastupovanie v katastrálnom konaní',
  },
  {
    head: 'Notár',
    text:
      'môže vykonávať notársku činnosť, len ak uzavrel zmluvu o poistení zodpovednosti za škodu, ktorá by mohla vzniknúť v súvislosti s vykonávaním notárskej činnosti',
  },
  {
    head: 'Notár',
    text:
      'a jeho zamestnanci sú povinní zachovávať mlčanlivosť o všetkých skutočnostiach, o ktorých sa dozvedeli pri výkone notárskej činnosti',
  },
  {
    head: 'Notár',
    text:
      'má nárok na odmenu za poskytnutú právnu službu určenú všeobecne záväzným právnym predpisom a to vyhláškou ministra spravodlivosti Slovenskej republiky č. 31/1993 Z.z.',
  },
];

const box = ({ head, text }) => (
  <div className="box">
    <p>
      <strong>{head}</strong>
    </p>
    <p>"{text}"</p>
  </div>
);

const ServicesAndLegislationPage = () => {
  return (
    <ServicesAndLegislationPageSC>
      <PageTitle title="Služby a legislatíva" background={ServicesDecorImage3x} />
      <ServiceSection />

      <div className="why">
        <div className="left">
          <HeadingTwo>Prečo práve notár</HeadingTwo>
          {blabla.filter((item, i) => i < 8).map((item) => box(item))}
        </div>
        <div className="right">
          <img src={DurdikImageJpg} alt="durdik" />
          {blabla.filter((item, i) => i >= 8).map((item) => box(item))}
        </div>
      </div>

      {/*<DocumentsSection title="Notárska legislatíva" />*/}
      {/*<QuoteSection />*/}
      {/*<DownloadSection title="Užitočné vzorové tlačivá" />*/}
    </ServicesAndLegislationPageSC>
  );
};

export default ServicesAndLegislationPage;
