import styled from 'styled-components';

import { COLOR_DARK_GREY_1 } from '../../../constants';

export default styled.div`
  font-weight: bold;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .row {
    margin-top: 30px;
    border-bottom: 1px #e2e8ed solid;

    .job {
      line-height: 1.67;
      margin-bottom: 0px;
    }

    .year {
      color: ${COLOR_DARK_GREY_1};
      margin-bottom: 30px;
    }

    .position {
      color: ${COLOR_DARK_GREY_1};
      margin-bottom: 20px;
      font-size: 15px;
    }
  }
`;
