import styled from 'styled-components';
import { COLOR_PRIMARY_WHITE_1 } from '../../constants';

import { bpMediumMax, bpXSmallMax, bpLargeMax } from '../../assets/styles/breakpoints';

export default styled.section`
  max-width: 1170px;
  margin: 150px auto 100px auto;

  .row {
    display: flex;
    max-width: 100%;

    .left-side {
      height: 100%;
      width: 50%;
      background-color: ${COLOR_PRIMARY_WHITE_1};
    }
    .right-side {
      height: 100%;
      width: 50%;
      background-color: ${COLOR_PRIMARY_WHITE_1};
    }
  }

  @media (max-width: ${bpLargeMax}px) {
    margin: 150px 50px 100px 50px;
  }

  @media (max-width: ${bpMediumMax}px) {
    .row {
      flex-direction: column;
      .left-side {
        width: 100%;
      }
      .right-side {
        width: 100%;
      }
    }
  }

  @media (max-width: ${bpXSmallMax}px) {
    margin: 100px 30px 100px 30px;
    .name {
      font-size: 40px;
      line-height: 50px;
    }
  }
`;
