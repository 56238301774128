import styled from 'styled-components';

import { bpLargeMax, bpMediumMax, bpXSmallMax } from '../../assets/styles/breakpoints';

export default styled.div`
  display: flex;
  /*height: 744px;*/
  max-width: 1170px;
  margin: 150px auto 50px auto;

  .name {
    width: 44%;
  }

  .about-text {
    display: flex;
    flex-direction: column;
    width: 56%;
    padding-left: 15px;
  }

  @media (max-width: ${bpLargeMax}px) {
    margin: 150px 50px 50px 50px;
  }

  @media (max-width: ${bpMediumMax}px) {
    flex-direction: column;
    .name {
      width: 100%;
    }
    .about-text {
      width: 100%;
      padding-left: 0px;
    }
  }

  @media (max-width: ${bpXSmallMax}px) {
    margin: 100px 30px 50px 30px;
    .name {
      font-size: 40px;
      line-height: 50px;
    }
  }
`;
