import styled from 'styled-components';

import { COLOR_BLUE_GREY_1 } from '../../constants';
import { bpMediumMax } from '../../assets/styles/breakpoints';

export default styled.section`
  width: 100%;
  position: relative;
  .row {
    display: flex;
    height: 100%;
    width: 100%;

    .left-side {
      height: 100%;
      width: 50%;
      background-color: ${COLOR_BLUE_GREY_1};
      position: relative;

      .text {
        margin: 150.5px 116px 151.5px 135px;
        width: 470px;
        height: 360px;

        .button {
          margin-left: 0;
        }
      }
    }
    .right-side {
      height: 100%;
      width: 50%;
      position: relative;
    }
  }

  .bottom-side {
    height: 198px;
    width: 905px;
    position: absolute;
    background-color: white;
    bottom: 0;

    .logos {
      margin: 53px auto auto 105px;
      display: flex;

      picture,
      img {
        margin: 30px;

        &.logo-one {
          margin: 30px 0;
        }
      }
    }
  }

  @media (max-width: ${bpMediumMax}px) {
    .row {
      flex-direction: column-reverse;

      .left-side,
      .right-side {
        width: 100%;
      }

      .left-side {
        .text {
          padding-left: 30px;
          padding-right: 30px;
          margin: 30px 0;
          height: 100%;
          width: 100%;
        }
      }

      img,
      picture {
        width: 100%;
      }
    }
    .bottom-side {
      position: relative;
      display: flex;
      width: 100%;
      height: auto;

      .logos {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;

        img {
          margin: 15px;

          &.logo-one {
            margin: 0 15px;
          }
        }
      }
    }
  }
`;
