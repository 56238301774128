import React from 'react';

import NewsPageSC from './news-page.styled';
import PageTitle from '../../components/page-title';
import NewsTile from './components/news-tile';
import NEWS_DATA from './components/news-tile/news.data';

const NewsPage = () => {
  return (
    <NewsPageSC>
      <PageTitle title="Novinky" />
      <div className="feed-container">
        {NEWS_DATA.filter((item, idx) => idx < 9).map(({ id, ...otherNewsProps }) => (
          <NewsTile key={id} {...otherNewsProps} />
        ))}
      </div>
    </NewsPageSC>
  );
};

export default NewsPage;
