import styled from 'styled-components';
import { COLOR_DARK_GREY_1 } from '../../constants';

export default styled.div`
  .news-title {
    max-width: 956px;
    margin: 50px auto 0 auto;

    .news-info {
      display: flex;

      .date {
        padding-right: 20.5px;
      }
    }
  }
  .wysiwyg-content {
    margin-top: 30px;

    p,
    h2,
    h3,
    ol,
    ul {
      max-width: 800px;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: auto;
      margin-right: auto;
    }


    p.img-alt {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin-top: 20px;
      margin-bottom: 50px;
      font-size: 14px;
      line-height: 1.43;
      color: ${COLOR_DARK_GREY_1};
    }
  }
`;
