import styled from 'styled-components';

import { bpLargeMax, bpMediumMax, bpXSmallMax } from '../../assets/styles/breakpoints';

export default styled.div`
  height: 704px;
  margin: 100px auto 0px auto;
  display: flex;
  max-width: 1170px;

  .title-container {
    width: 50%;

    .title {
      max-width: 268px;
    }
  }

  .document-list {
    width: 50%;
    padding-left: 16px;
  }

  @media (max-width: ${bpLargeMax}px) {
    margin: 100px 50px 0 50px;
  }

  @media (max-width: ${bpMediumMax}px) {
    flex-direction: column;
    .title-container {
      width: 100%;
    }
    .document-list {
      width: 100%;
      padding-left: 0px;
    }
  }

  @media (max-width: ${bpXSmallMax}px) {
    margin: 100px 30px 0 30px;
  }
`;
