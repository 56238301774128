import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContentWrapperSC = styled.div`
  max-width: 956px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const ContentWrapper = (props) => {
  const { children, className } = props;
  return(<ContentWrapperSC className={className !== '' ? className : null}>{children}</ContentWrapperSC>);
};

ContentWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
};

ContentWrapper.defaultProps = {
  className: '',
};

export default ContentWrapper;
