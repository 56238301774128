import React from 'react';
import LogoTwoImageSC from './logo-two-image.styled';
import LogoTwo from '../../../assets/img/jpg/logo2.jpg';

export default () => {
  return (
    <LogoTwoImageSC>
      <a href="https://www.sak.sk/" target="_blank" rel="noopener noreferrer">
        <img src={LogoTwo} alt="Logo one" />
      </a>
    </LogoTwoImageSC>
  );
};
