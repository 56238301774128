import styled from 'styled-components';
import NewsDecorImage2x from '../../assets/img/png/novinky-decor@2x.png';

export default styled.section`
  width: 100%;
  min-height: 200px;
  background-image: url(${NewsDecorImage2x});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;

  .title-container {
    width: 100%;
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;

    .title-heading {
      margin-top: 80px;
      text-align: left;
    }
  }
`;
