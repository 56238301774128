import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { COLOR_BLACK_1, COLOR_PRIMARY_WHITE_1 } from '../../constants';

export const HeadingThreeSC = styled.h3`
  ${(props) => {
    if (props.type === 'reversed')
      return `
        color: ${COLOR_PRIMARY_WHITE_1};
        font-size: 35px;
        line-height: 48px;
      `;

    if (props.type === 'primary')
      return `
        color: ${COLOR_BLACK_1};
        font-size: 27px;
        line-height: 40px;
      `;
  }}

  margin-bottom: 20px;
  font-weight: 400;
  font-family: 'PT Serif', serif;
`;

const HeadingThree = (props) => {
  const { children, className, type } = props;
  const customClass = classNames({ [className]: className !== undefined });

  return (
    <HeadingThreeSC className={customClass} type={type}>
      {children}
    </HeadingThreeSC>
  );
};

HeadingThree.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
};

HeadingThree.defaultProps = {
  className: undefined,
  type: 'primary',
};

export default HeadingThree;
