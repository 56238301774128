import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { COLOR_BLACK_1, COLOR_PRIMARY_WHITE_1 } from '../../constants';

export const HeadingFourSC = styled.h4`
   ${(props) => {
     if (props.type === 'reversed')
       return `
        color: ${COLOR_PRIMARY_WHITE_1};
        font-size: 30px;
        line-height: 40px;
    `;

   if (props.type === 'primary')
     return `
      color: ${COLOR_BLACK_1};
      font-size: 18px;
      line-height: 30px;
     `;
   }}

   font-weight: 700;
   margin-bottom: 10px;
   font-family: 'PT Serif', serif;
 `;

const HeadingFour = (props) => {
  const { children, className, type } = props;
  const customClass = classNames({ [className]: className !== undefined });

  return (
    <HeadingFourSC className={customClass} type={type}>
      {children}
    </HeadingFourSC>
  );
};

HeadingFour.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
};

HeadingFour.defaultProps = {
  className: undefined,
  type: 'primary',
};

export default HeadingFour;
