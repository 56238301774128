import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import {
  COLOR_PRIMARY_BLACK_1,
  COLOR_BLUEGREY_1,
  COLOR_PRIMARY_WHITE_1,
  COLOR_LIGHTBLUE_1,
  COLOR_BORDER_BLUEGREY_1,
  COLOR_LIGHTGREY_1,
  COLOR_WHITE_02,
} from '../../constants';

const ButtonSC = styled.button`
  ${(props) => {
    if (props.type === 'primary-reversed')
      return `
        color: ${COLOR_PRIMARY_BLACK_1};
        background-color: ${COLOR_PRIMARY_WHITE_1};
        :hover { 
          background-color: ${COLOR_LIGHTGREY_1}; 
        }; 
        :disabled {
          color: ${COLOR_WHITE_02};
          background-color: ${COLOR_PRIMARY_BLACK_1};
        };
      `;
    if (props.type === 'secondary')
      return `
        color: ${COLOR_PRIMARY_BLACK_1}; 
        background-color: ${COLOR_PRIMARY_WHITE_1}; 
        border: 1px solid ${COLOR_LIGHTBLUE_1}; 
        :hover {
          border: 1px solid ${COLOR_BORDER_BLUEGREY_1};
        }; 
        :active {
          border: 1px solid ${COLOR_PRIMARY_BLACK_1}; 
          background-color: ${COLOR_LIGHTBLUE_1};
        }; 
        :disabled {
          color: ${COLOR_WHITE_02};
          background-color: ${COLOR_PRIMARY_BLACK_1};
        };
      `;

    return `
        color: ${COLOR_PRIMARY_WHITE_1}; 
        background-color: ${COLOR_PRIMARY_BLACK_1}; 
        :hover { 
          background-color: ${COLOR_BLUEGREY_1}; 
        }; 
        :active {
          background-color: ${COLOR_PRIMARY_BLACK_1};
        }; 
        :disabled {
          color: ${COLOR_WHITE_02};
          background-color: ${COLOR_PRIMARY_BLACK_1}; 
        };
      `;
  }}

  box-sizing: border-box;
  font-size: 17px;
  letter-spacing: 0.47px;
  line-height: 30px;
  padding: 20px 50px;
  border-radius: 35px;
  font-family: 'PT Serif', serif;
  &:focus {
    outline: 0;
  }
`;

const Button = (props) => {
  const { children, className, type, disabled } = props;
  const customClass = classNames({ [className]: className !== undefined });

  return (
    <ButtonSC className={customClass} type={type} disabled={disabled}>
      {children}
    </ButtonSC>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  className: undefined,
  type: 'primary',
  disabled: false,
};

export default Button;
